import React from 'react'
import MessageProvider from '@packages/justo-parts/lib/components/Provider/Message'
import ApolloServicesClients from '@providers/apollo/ApolloServicesClients'
import {GlobalState} from '@providers/GlobalState'
import MobileOrDesktopContext from '@providers/MobileOrDesktopContext'
import Session from '@providers/Session'
import WebsiteIdContext from '@providers/WebsiteIdContext'
import dynamic from 'next/dynamic'

import '@providers/locale'

const NonSsrRoot = dynamic(() => import('./NonSsrRoot'), {ssr: false})

export default function Root({
  children,
  websiteId,
  mobileOrDesktop
}: {
  children: React.ReactNode
  websiteId?: string
  mobileOrDesktop?: 'mobile' | 'desktop'
}) {
  return (
    <ApolloServicesClients>
      <GlobalState>
        <MobileOrDesktopContext.Provider value={mobileOrDesktop}>
          <WebsiteIdContext.Provider value={websiteId}>
            <MessageProvider>
              <Session>
                <NonSsrRoot />
                {children}
              </Session>
            </MessageProvider>
          </WebsiteIdContext.Provider>
        </MobileOrDesktopContext.Provider>
      </GlobalState>
    </ApolloServicesClients>
  )
}
