import React from 'react'
import {ApolloProvider as OriginalApolloProvider} from '@apollo/client' // Used inside AutoForm
import {ApolloProvider as HooksApolloProvider} from 'apollo-hooks'

import getClients from './getClients'

export default function ApolloServicesClients(props) {
  const clients = getClients()
  return (
    <HooksApolloProvider {...clients} client={clients.main}>
      <OriginalApolloProvider client={clients.main}>{props.children}</OriginalApolloProvider>
    </HooksApolloProvider>
  )
}
