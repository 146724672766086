import React, {useState} from 'react'
import useOnEvent from 'react-app-events/lib/useOnEvent'
import SessionContext from '@helpers/auth/SessionContext'

import getJWTSavedData from './apollo/getJWTSavedData'

export default function SessionContextProvider(props) {
  const jwtData = getJWTSavedData()

  const [session, setSession] = useState(jwtData)

  useOnEvent('saveSession', async newSession => {
    setSession(newSession)

    if (newSession) {
      // We use a dynamic import to reduce the bundle size of _app.tsx
      const {sendPosthogUserInfo, setPosthogUserId} = await import('@helpers/posthog')

      setPosthogUserId(newSession.userId)
      sendPosthogUserInfo(newSession)
    }
  })

  return <SessionContext.Provider value={session || {}}>{props.children}</SessionContext.Provider>
}
